/// <amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/reactSpa/app"/>
import * as React from "react";
import { useEffect, useState } from "react";
import { HashRouter as Router } from "react-router-dom";
import { get } from "Core/Medius.Core.Web/Scripts/Medius/core/fetch/rest";
import { handleError } from "Core/Medius.Core.Web/Scripts/lib/errorHandling/errorHandler";
import { translate } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { Provider } from "react-redux";
import { store } from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { getCurrentCulture } from "Core/Medius.Core.Web/Scripts/lib/reactIntegration/kendoIntl";
import { setAccesses } from "Core/Medius.Core.Web/Scripts/AdminPages/accessState";
import { AppRoutes } from "./routes";
import { setDocumentTypes } from "Core/Medius.Core.Web/Scripts/components/topBar/documentTypesState";
import { LocalizationContext, StringMap } from '@medius/ui-controls';
import { getCultureCode } from "Core/Medius.Core.Web/Scripts/Medius/lib/utils/user";
import { mapFromNorthstarToApa } from "Core/Medius.Core.Web/Scripts/lib/northstarLocalization";

export enum Module {
    Custom = 0,
    Standard,
    Match,
    Procurement,
    Contract,
    PaymentRequest,
    DocumentApproval,
    AdjustmentRequest,
    DemoManager,
    TenantManager,
    CFODashboards,
    ExtendedReports,
    BusinessLog,
    ContractManagement,
    PDMS,
    Web3Procurement,
    SavingsTracker,
    Sourcing,
    SRM,
    Connect,
    Analytics,
    Pay,
    SupplierPortal,
    Capture,
    PV2,
    SIM,
    Budget,
    FraudAndRiskProtection,
    ExpenseManagement,
    Prepayment,
    APACopilot
}

interface ApplicationDataDto {
    accesses: AccessesDto;
    documentTypes: DocumentTypesDto;
}

interface AccessesDto {
    documentManagement: boolean;
    emailSettings: boolean;
    resolutionConfiguration: boolean;
    demoManager: boolean;
    passwordManagement: boolean;
    importManagement: boolean;
    documentCacheManagement: boolean;
    masterDataReindex: boolean;
    messageManagement: boolean;
    payApprovalRule: boolean;
    importConfiguration: boolean;
    enabledModules: Array<Module>;
    supportPortal: boolean;
    permissionGroups: boolean;
    sendInvoiceAsEmail: boolean;
    procurementV2: boolean;
    contractCategoryAdministration: boolean;
    purgeInvoices: boolean;
    userAdministration: boolean;
    procurementAdministration: boolean;
    deliveryPartnersSupport: boolean;
    globalSettingsLinks: boolean;
    budgetConfigurationAdministration: boolean;
    budgetKeyAdministration: boolean;
    budgetKeyEdit: boolean;
    domainAuthentication: boolean;
    addressManagement: boolean;
    catalogManagement: boolean;
}

interface DocumentTypeDto {
    readonly type: string;
    readonly nameTranslationKey?: string;
}

interface DocumentTypesDto {
    readonly documentTypesForCreate: DocumentTypeDto[],
    readonly documentTypesForRead: DocumentTypeDto[]
}

export function App() {
    const [accessesLoaded, setAccessesLoaded] = useState(false);

    async function getApplicationData() {
        try {
            const dataDto = await get<ApplicationDataDto>("applicationData");
            store.dispatch(setAccesses(dataDto.accesses));
            store.dispatch(setDocumentTypes(dataDto.documentTypes));
            setAccessesLoaded(true);
        } catch (e) {
            handleError(e);
        }
    }

    useEffect(() => {
        getApplicationData();
    }, []);

    if (!accessesLoaded)
        return (<>{translate("#Core/loading")}</>);

    return (
        <LocalizationContext.Provider value={{
            translate: (key: string, _, defaultValue?: string, args?: StringMap) => mapFromNorthstarToApa(key, defaultValue, args),
            getLocale: () => getCultureCode()
        }} >
            <IntlProvider locale={getCurrentCulture()}>
                <LocalizationProvider language="current-language">
                    <Provider store={store}>
                        <Router>
                            <AppRoutes />
                        </Router>
                    </Provider>
                </LocalizationProvider>
            </IntlProvider>
        </LocalizationContext.Provider>
    );
}