///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/task/models/taskContext"/>
import * as ko from "knockout";
import * as _ from "underscore";
import * as globalization from "Core/Medius.Core.Web/Scripts/lib/globalization";
import contextFactory = require("Core/Medius.Core.Web/Scripts/Medius/core/viewmodels/context");
import * as taskHandlingContext from "Core/Medius.Core.Web/Scripts/Medius/apps/task/models/taskHandlingContext";
import * as koUtils from "Core/Medius.Core.Web/Scripts/Medius/knockout/utils";
import * as simplePresenter from "Core/Medius.Core.Web/Scripts/Medius/core/metadata/dataTransfer/simplePresenter";
import * as topBarMessageProviderCollection from "Core/Medius.Core.Web/Scripts/Medius/apps/task/models/topBarMessageProviderCollection";
import * as settings from "Core/Medius.Core.Web/Scripts/Medius/apps/task/settings";
import * as customModelProvider from "Core/Medius.Core.Web/Scripts/components/customDocumentModel/customDocumentModelProvider";
import * as shortcutManager from "Core/Medius.Core.Web/Scripts/lib/shortcutManager";
import { InvoiceSummary } from "Core/Medius.Core.Web/Scripts/shared/components/invoiceSummary";
import { isTabVisible } from "Core/Medius.Core.Web/Scripts/customTabs/customTabs";
import { observe } from "Core/Medius.Core.Web/Scripts/shared/store/reduxStore";

import { TaskTabsTemplate } from "Core/Medius.Core.Web/Scripts/components/administrationEditor/TaskTabsTemplate";
import { isNotNullOrUndefined } from "Core/Medius.Core.Web/Scripts/lib/underscoreHelpers";
import { LabelSelector } from "Core/Medius.Core.Web/Scripts/Medius/apps/inbox/task/labelSelector";

import * as taskHandlingEvents from "Core/Medius.Core.Web/Scripts/Medius/apps/task/models/taskEventsContext";
import * as documentContext from "Core/Medius.Core.Web/Scripts/Medius/apps/task/models/documentContext";
import RiskFactorIconComponent from "Core/Medius.Core.Web/Scripts/Medius/apps/task/RiskFactor/riskFactorIconComponent";
import { isCopyRequisitionEnabled, isCopilotNotificationWindowEnabled } from "Core/Medius.Core.Web/Scripts/Medius/core/featureToggle";
import { CopyRequisitionComponent } from "Core/Medius.Core.Web/Scripts/Medius/components/components/copyRequisition/copyPurchaseRequisitionComponent";
import { Module } from "Core/Medius.Core.Web/Scripts/Medius/apps/reactSpa/app";
import { get } from "Core/Medius.Core.Web/Scripts/Medius/core/communication/json/rest";
import { handleAnyError } from "Core/Medius.Core.Web/Scripts/Medius/core/backendErrorHandler";
import AgentIconComponent from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/AgentIconComponent";
import { calculateAgentVisibility, calculateOperational, getDelegatedMessage, getEscalationMessage } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/utils";
import { getDocumentContext } from "../../../mediusUtils";
import { NotificationWindow } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/NotificationWindow/NotificationWindow";
import { IPredefinedQuestion } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/agentInterfaces";
import * as copilotServices from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/services/agentServices";
import { operationalPredefinedQuestions, nonOperationalPredefinedQuestions } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/AgentConsts";

function prepareTaskContext(task: any) {
    const context = contextFactory();
    taskHandlingContext.extendViewmodelContextFromTaskData(context, task);
    taskHandlingEvents.extendViewmodelContext(context);
    documentContext.extendViewModelContextFromDocument(context, task.Document);
    return context;
}

class TaskContext {
    Task: any;
    Tabs: any;
    viewmodelContext: any;
    options: any;
    CustomDocumentModel: any = null;
    isTabVisible: ko.Observable<any> = ko.observable({});
    TaskTabs: ko.PureComputed<{ functionComponent: ({ tabs, activateTab }: import("Core/Medius.Core.Web/Scripts/components/administrationEditor/TaskTabsTemplate").TaskTabsProps) => JSX.Element; props: { tabs: any; activateTab: (tabName: any) => void; }; }>;
    renderedTabs: any;
    visibleTabs: ko.Computed<any>;
    activateTab: (tabToDeactivate: any, tabToActivate: any) => void;
    switchToNextTab: () => void;
    switchToPreviousTab: () => void;
    mod: (n: any, m: any) => number;
    tabActivated: (activatedTab: any) => void;
    dropdownTabs: ko.Computed<any>;
    isDropdownTabsVisible: ko.Computed<any>;
    unsubscribeFromStore: any;
    reduxStoreAccessesListener: any;
    isFraudModuleEnabled: ko.Observable<boolean> = ko.observable(false);
    isAgentModuleEnabled: ko.Observable<boolean> = ko.observable(false);
    isAgentVisible: ko.Computed<boolean>;
    identifier: ko.Computed<any>;
    getSubIdentifiers: () => any;
    InvoiceSummaryConfig: ko.PureComputed<{ functionComponent: any; props: { identifiers: any; }; }>;
    messages: ko.Computed<any[]>;
    showMessages: ko.Computed<boolean>;
    listedTabs: ko.Computed<any>;
    labelsHandlerComponentConfiguration: ko.Computed;
    riskFactorComponentConfiguration: ko.Computed;
    isPV2Enabled: ko.Observable<boolean>;
    setIsPV2Enabled: () => void;
    showCopyRequisitionButton: ko.Computed<boolean>;
    copyRequisitionButton: ko.Computed;
    mediusAgentFullscreenConfiguration: ko.Computed;
    mediusAgentInboxConfiguration: ko.Computed;
    copilotNotificationWindow: ko.Computed;
    copilotPredefinedQuestions: ko.ObservableArray<IPredefinedQuestion> = ko.observableArray([]);

    constructor(context: any, taskVm: any, tabsVm: any, task: any, options: any) {
        if (!context || typeof context !== "object") {
            throw new Error("Context has not been provided");
        }

        if (!taskVm || typeof taskVm !== "object") {
            throw new Error("Task viewmodel has not been provided");
        }

        if (!tabsVm || typeof tabsVm !== "object") {
            throw new Error("Tabs viewmodel has not been provided");
        }

        if (!task || typeof task !== "object") {
            throw new Error("Task has not been provided");
        }

        if (!ko.isObservable(options.FullscreenEnabled)) {
            options.FullscreenEnabled = ko.observable(false);
        }

        taskHandlingContext.extendViewmodelContextFromTaskData(context, task, options.FullscreenEnabled);
        taskHandlingEvents.extendViewmodelContext(context);
        documentContext.extendViewModelContextFromDocument(context, task.Document);
        this.Task = taskVm;
        this.Tabs = tabsVm;
        this.viewmodelContext = context;
        this.options = options;
        this.isPV2Enabled = ko.observable(false);

        this.labelsHandlerComponentConfiguration = ko.computed(() => {
            const taskId = taskVm.Id();
            return ({
                functionComponent: LabelSelector,
                props: {
                    taskId: taskId
                }
            });
        });

        const taskDocument = koUtils.unpack(this.Task.Document);
        
        if (
            taskDocument &&
            !taskDocument.isLongTermArchived &&
            (taskDocument.$type() === "Medius.ExpenseInvoice.Entities.ExpenseInvoice, Medius.ExpenseInvoice.Common" ||
                taskDocument.$type() === "Medius.OrderbasedInvoice.Entities.OrderbasedInvoice, Medius.OrderbasedInvoice.Common" ||
                taskDocument.$type() === "Medius.ContractbasedInvoice.Entities.ContractbasedInvoice, Medius.ContractbasedInvoice.Common" ||
                taskDocument.$type() === "Medius.Core.Entities.Integration.EDIDocumentImport, Medius.Core.Common")
        ) {
            this.riskFactorComponentConfiguration = ko.computed(() => {
                return ({
                    functionComponent: RiskFactorIconComponent,
                    props: {
                        taskId: taskVm.Id()
                    }
                });
            });
        }

        this.copyRequisitionButton = ko.computed(() => {
            if (taskVm.Document && typeof taskVm.Document === 'function'
                && taskVm.Document().Company && typeof taskVm.Document().Company === 'function'
                && taskVm.Document().$type() === "Medius.Procurement.Entities.PurchaseRequisition, Medius.Procurement.Common") {
                const companyId = taskVm.Document().Company().Id();
                const companyName = taskVm.Document().Company().Name();

                return {
                    functionComponent: CopyRequisitionComponent,
                    props: {
                        documentId: taskVm.Document().Id(),
                        documentIdentifier: taskVm.Document().Identifier(),
                        companyId: companyId,
                        companyName: companyName
                    }
                };
            }
        });

        this.setIsPV2Enabled = () => {
            get("applicationData")
                .fail(function (xhr: any) {
                    handleAnyError(xhr);
                })
                .then((response: { accesses: { enabledModules: any[]; }; }) => {
                    if (response) {
                        this.isPV2Enabled(response.accesses.enabledModules.includes(Module.PV2));
                    }
                });
        };

        this.setIsPV2Enabled();
        this.showCopyRequisitionButton = ko.computed(() => this.isPV2Enabled() && isCopyRequisitionEnabled() && taskDocument && taskDocument.$type() === "Medius.Procurement.Entities.PurchaseRequisition, Medius.Procurement.Common");

       
        const isMediusCopilotOperational = calculateOperational(taskDocument.$type(), this.Task.Description());
        if(isMediusCopilotOperational){
            copilotServices.getPredefinedQuestions().then(questions => {
                this.copilotPredefinedQuestions(questions);
            });
        } else {
            copilotServices.getPredefinedQuestionsNotFullyOperational().then(questions => {
                this.copilotPredefinedQuestions(questions);
            });
        }

        this.isAgentVisible = ko.computed(() => {
            return calculateAgentVisibility(this.isAgentModuleEnabled(), taskDocument);
        });

        if(isCopilotNotificationWindowEnabled()){    
            this.copilotNotificationWindow = ko.pureComputed(() => ({
                functionComponent: NotificationWindow,
                props: {
                    predefinedQuestions: isMediusCopilotOperational && this.copilotPredefinedQuestions().filter(predefinedQuestion => {
                        
                        if(
                            predefinedQuestion.value === "#Core/TellMeMoreAboutTheSupplier" || 
                            predefinedQuestion.value === "#Core/ShouldIApproveThisInvoice" ||
                            predefinedQuestion.value === "#Core/WhyHaveIReceivedThisInvoice" ||
                            predefinedQuestion.value === "#Core/SummarizeTheInvoiceContext"
                        ) return true;
                        else return false;
                    })
                }
            }));
        }

        this.mediusAgentFullscreenConfiguration = ko.pureComputed(() => {
            const invoiceContext = getDocumentContext(context);

            return ({
                functionComponent: AgentIconComponent,
                props: {
                    isInFullscreen: true,
                    documentId: this.Task.Document().Id(),
                    taskName: this.Task.description,
                    isOperational: isMediusCopilotOperational,
                    invoiceType: invoiceContext.DocumentTypeName,
                    workflowStepName: invoiceContext.WorkflowStepName,
                    getDelegationMessage: () => getDelegatedMessage(this.messages()),
                    getEscalationMessage: () => getEscalationMessage(this.messages()),
                    predefinedQuestions: this.copilotPredefinedQuestions()
                }
            });
        });

        this.mediusAgentInboxConfiguration = ko.pureComputed(() => {
            const invoiceContext = getDocumentContext(context);

            return ({
                functionComponent: AgentIconComponent,
                props: {
                    isInFullscreen: false,
                    documentId: this.Task.Document().Id(),
                    taskName: this.Task.description,
                    isOperational: isMediusCopilotOperational,
                    invoiceType: invoiceContext.DocumentTypeName,
                    workflowStepName: invoiceContext.WorkflowStepName,
                    getDelegationMessage: () => getDelegatedMessage(this.messages()),
                    getEscalationMessage: () => getEscalationMessage(this.messages()),
                    predefinedQuestions: this.copilotPredefinedQuestions()
                }
            });
        });

        this.TaskTabs = ko.pureComputed(() => {
            const tabs: { tabName: string, tabTestId: string, tabActive: boolean } = this.renderedTabs().map((tab: any) => {
                return { tabName: tab.TabName(), tabTestId: tab.tabHref(), tabActive: tab.active() };
            });

            return ({
                functionComponent: TaskTabsTemplate,
                props: {
                    tabs: tabs,
                    activateTab: (tabName: any) => {
                        const tabToActivate = ko.utils.arrayFirst(this.renderedTabs(), (tab) => {
                            return tab.TabName() == tabName;
                        });
                        const tabToDeactivate = ko.utils.arrayFirst(this.renderedTabs(), (tab) => {
                            return tab.active() == true;
                        });
                        if (isNotNullOrUndefined(tabToActivate) && isNotNullOrUndefined(tabToDeactivate) && tabToActivate !== tabToDeactivate) {
                            tabToActivate.activate();
                            tabToDeactivate.deactivate();
                        }
                    }
                }
            });
        });

        this.visibleTabs = ko.computed({
            read: () => {
                const tabs = this.Tabs.filter((tab: any) => {
                    return tab.isOfCustomType()
                        ? this.isTabVisible()[tab.tabViewPath()]
                        : true;
                });
                return tabs;
            },
            deferEvaluation: true
        });


        this.renderedTabs = ko.computed({
            read: () => {
                let tabs;
                if (ko.isObservable(options.FullscreenEnabled)) {
                    const isFullscreen = options.FullscreenEnabled();
                    tabs = isFullscreen ? this.getNonFullscreenTabs() : this.getAllTabs();
                } else {
                    tabs = this.getAllTabs();
                }

                return tabs;
            },
            deferEvaluation: true
        });

        this.activateTab = (tabToDeactivate, tabToActivate) => {
            $('.nav-tabs a[href="' + tabToActivate.tabHref() + '"]').tab('show');
            tabToActivate.IsTabActive(true);
            tabToDeactivate.IsTabActive(false);
        };

        this.switchToNextTab = () => {
            const renderedTabs = this.renderedTabs();

            for (let i = 0; i < renderedTabs.length; i++) {
                const currentTab = renderedTabs[i];
                if (currentTab.IsTabActive()) {
                    this.activateTab(currentTab, renderedTabs[this.mod(i + 1, renderedTabs.length)]);
                    return;
                }
            }
        };

        this.switchToPreviousTab = () => {
            const renderedTabs = this.renderedTabs();

            for (let i = 0; i < renderedTabs.length; i++) {
                const currentTab = renderedTabs[i];
                if (currentTab.IsTabActive()) {
                    this.activateTab(currentTab, renderedTabs[this.mod(i - 1, renderedTabs.length)]);
                    return;
                }
            }
        };

        this.mod = (n: number, m: number) => {
            return ((n % m) + m) % m;
        };

        shortcutManager.bindShortcutWithLogging("SwitchToNextTab", shortcutManager.nonInputShortcutCallback(this.switchToNextTab.bind(this)));
        shortcutManager.bindShortcutWithLogging("SwitchToPreviousTab", shortcutManager.nonInputShortcutCallback(this.switchToPreviousTab.bind(this)));

        this.renderedTabs().forEach(
            (tab: any) => {
                tab.wasActivated.subscribe(() => {
                    this.tabActivated(tab);
                });
            });

        this.tabActivated = (activatedTab) => {
            this.renderedTabs().forEach(
                (tab: any) => {
                    tab.IsTabActive(tab === activatedTab);
                });
        };

        this.dropdownTabs = ko.computed({
            read: () => {
                return this.renderedTabs().filter((tab: any) => {
                    return tab.isDropdown();
                });
            },
            deferEvaluation: true
        });

        this.isDropdownTabsVisible = ko.computed({
            read: () => {
                return this.renderedTabs().some((tab: any) => {
                    return tab.isDropdown();
                });
            },
            deferEvaluation: true
        });

        this.reduxStoreAccessesListener = observe(state => state.accesses, accesses => {
            const agentEnabled = accesses?.enabledModules.includes(Module.APACopilot);
            const fraudEnabled = accesses?.enabledModules.includes(Module.FraudAndRiskProtection);
            this.isAgentModuleEnabled(agentEnabled);
            this.isFraudModuleEnabled(fraudEnabled);
        });

        this.unsubscribeFromStore = observe((state) => { return state; }, (state) => {
            if (state.accesses && state.documentTypes.documentTypesForRead) {
                this.getCustomTabs().forEach((customTab: any) => {
                    const currentState = this.isTabVisible();

                    if (isTabVisible(customTab.tabViewPath(), koUtils.unpack(task.Document))) {
                        currentState[customTab.tabViewPath()] = true;
                        this.isTabVisible(currentState);
                    }
                    else {
                        currentState[customTab.tabViewPath()] = false;
                        this.isTabVisible(currentState);
                    }
                });
            }
        });

        this.listedTabs = ko.computed({
            read: () => {
                return this.renderedTabs().filter((tab: any) => {
                    return !tab.isDropdown();
                });
            },
            deferEvaluation: true
        });

        this.identifier = ko.computed({
            read: () => {
                const task = koUtils.unpack(this.Task);
                const taskDocument = koUtils.unpack(task.Document);
                const taskDescription = koUtils.unpack(task.Description);
                const documentIdentifier = simplePresenter.presentMetadata(taskDocument).longText;
                const description = `${taskDescription} - ${documentIdentifier}`;

                return description;
            },
            deferEvaluation: true
        });

        this.getSubIdentifiers = () => {
            const task = koUtils.unpack(this.Task),
                taskDocument = koUtils.unpack(task.Document);
            let subIdentifiers = simplePresenter.presentMetadata(taskDocument).subIdentifiers;

            if (!subIdentifiers) {
                subIdentifiers = [];
            }

            return subIdentifiers;
        };

        this.InvoiceSummaryConfig = ko.pureComputed(() => {
            const task = koUtils.unpack(this.Task),
                taskDocument = koUtils.unpack(task.Document);
            return ({
                functionComponent: InvoiceSummary,
                props: {
                    identifiers: koUtils.unpack(taskDocument.identifiers),
                }
            });
        });

        this.messages = ko.computed({
            read: () => {
                const task = koUtils.unpack(this.Task),
                    taskDocument = koUtils.unpack(task.Document),
                    clientSideMessages = topBarMessageProviderCollection
                        .getMessages(this.viewmodelContext, taskDocument, taskDocument.$type(), task);

                let allMessages = [];

                if (isNotNullOrUndefined(ko.unwrap(task.Perspective))) {
                    allMessages = task.Perspective().Messages();
                }

                if (isNotNullOrUndefined(ko.unwrap(task.Document)) &&
                    isNotNullOrUndefined(ko.unwrap(task.Document().Perspective)) &&
                    isNotNullOrUndefined(ko.unwrap(task.Document().Perspective().Messages))) {
                    allMessages = allMessages.concat(task.Document().Perspective().Messages());
                }

                allMessages = allMessages.concat(clientSideMessages);

                return _.map(allMessages, (msg) => { return globalization.getLabelTranslation(msg); });
            },
            deferEvaluation: true
        });

        this.showMessages = ko.computed({
            read: () => {
                return this.messages().length > 0;
            },
            deferEvaluation: true
        });
    }

    dispose() {
        if (this.Task && this.Task.disposeContext) {
            this.Task.disposeContext();
        }

        this.showMessages.dispose();
        this.messages.dispose();
        this.identifier.dispose();
        this.listedTabs.dispose();
        this.isDropdownTabsVisible.dispose();
        this.dropdownTabs.dispose();
        this.unsubscribeFromStore();
        this.reduxStoreAccessesListener();

        /* This computed refer to external subsribable 'isFullscreenEnabled' that
           lives longer that this object, therefore it needs to be disposed
           when no longer needed to avoid memory leaks */
        this.renderedTabs.dispose();

        this.visibleTabs.dispose();

        this.options = null;
        this.viewmodelContext = null;
        this.Tabs = null;
        this.Task = null;
    }

    /*
    * Workaround for bootstrap issue where tabs behave strange if non of them is
    * marked active. This creates problems in fullscreen mode, when fullscreen tab
    * is the same as default tab.
    * This method cheks if any of passed tabs is marked as active and if not, it
    * marks the first one on the list as active.
    */
    activateFirstIfNoOtherActive(tabs: any) {
        tabs = tabs || [];
        const hasActive = _(tabs).any((t) => {
            return t.active();
        });

        if (hasActive) {
            return;
        }

        if (tabs.length > 0) {
            tabs[0].activate();
        }
    }

    /*
    * Retrieve the tab configuration of tab marked as fullscreen. If multiple tabs are
    * marked as fullscreen, the first one is returned.
    */
    getFullscreenTab() {
        const fullscreenTab = _(this.visibleTabs()).find((t) => {
            return t.IsSeparateInFullscreen();
        });

        return fullscreenTab || this.visibleTabs()[0];
    }

    /*
    * Retrieves the list of tabs that are not marked as fullscreen tabs.
    */
    getNonFullscreenTabs() {
        const fullscreenTab = this.getFullscreenTab(),
            nonFullscreens = this.visibleTabs().filter((t: any) => {
                return t !== fullscreenTab;
            });
        fullscreenTab.wasActivated(true);
        this.activateFirstIfNoOtherActive(nonFullscreens);
        return nonFullscreens;
    }


    /*
   * Retrieves the labels handler which is context for labels edit and display
   */
    getLabelsHandler() {
        const taskDocument = koUtils.unpack(this.Task.Document);

        if (this.Task && this.options.FullscreenEnabled() && this.Task.getLabelsHandler &&
            taskDocument && !taskDocument.isLongTermArchived && taskDocument.$type() !== "Medius.Core.Entities.DocumentRegistration.Draft, Medius.Core.Common") {
            this.Task.getLabelsHandler();
        }
        return this.Task;
    }

    /*
    * Retrieves list of all the tabs defined in this context, usable for non-fullscreen mode.
    * In addition, it performs resetting of all the tabs activity state to the original values
    * before the 'activateFirstIfNoOtherActive' was applied. This makes sure that only tab
    * that was configured as IsDefault (from database), will be marked as active in returned
    * collection.
    */
    getAllTabs() {
        this.visibleTabs().forEach((t: any) => {
            t.resetActive();
        });
        return this.visibleTabs();
    }

    getCustomTabs() {
        return this.Tabs.filter((tab: any) => {
            return tab.tabViewPath().includes("CustomTabs");
        });
    }
}

class TaskContextWithCustomDocumentViewModel {
    CustomDocumentModel: any;
    dispose: () => void;
    constructor(customDocumentViewModel: any) {
        this.CustomDocumentModel = customDocumentViewModel;
        this.dispose = () => {
            this.CustomDocumentModel.dispose();
            this.CustomDocumentModel = null;
        };
    }
}

export function createFromDtos(task: any, tabs: any, options: any) {
    if (!task || typeof task !== "object") {
        throw new Error("Task has not been provided");
    }

    const customViewModelProvider = customModelProvider.get(task);
    if (customViewModelProvider) {
        const context = prepareTaskContext(task);
        return new TaskContextWithCustomDocumentViewModel(customViewModelProvider(task, context, options.FullscreenEnabled));
    }

    if (!tabs || typeof tabs !== "object") {
        throw new Error("Tabs have not been provided for current task");
    }

    const context = prepareTaskContext(task),
        taskVm = context.create(task),
        tabsVm = context.create(tabs);

    (context as any).events.trigger(settings.taskOpened);

    return new TaskContext(context, taskVm, tabsVm, task, options);
}

export function createFromTaskViewmodel(task: any, taskVm: any, tabs: any, options: any) {
    if (!taskVm) {
        throw new Error("Task viewmodel has not been provided");
    }

    const customViewModelProvider = customModelProvider.get(task);
    if (customViewModelProvider) {
        const context = prepareTaskContext(task);
        return new TaskContextWithCustomDocumentViewModel(customViewModelProvider(task, context, options.FullscreenEnabled));
    }

    if (!tabs || typeof tabs !== "object") {
        throw new Error("Tabs have not been provided for current task");
    }

    const context = taskVm._ctx,
        tabsVm = context.create(tabs);

    context.events.trigger(settings.taskReopened);

    return new TaskContext(context, taskVm, tabsVm, task, options);
}

export function filterPredifenedQuestionsToNotificationByOperationalContext(predefinedQuestions: IPredefinedQuestion[], isOperational:boolean){
    if(isOperational){
        return predefinedQuestions
            .filter(question => operationalPredefinedQuestions.includes(question.value));
    }
    
    return predefinedQuestions
        .filter(question => nonOperationalPredefinedQuestions.includes(question.value));
}