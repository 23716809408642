///<amd-module name = "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/ChatMessage"/>
import * as React from "react";
import { IMessage, Role } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/agentInterfaces";
import { SupplierWidget } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/widgets/SupplierWidget";
import { FlexLayout, Text } from "@medius/ui-controls";
import { getLabelTranslation } from "Core/Medius.Core.Web/Scripts/lib/globalization";
import { SourcesWidget } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/widgets/SourcesWidget";
import AgentFeedbackComponent from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/Components/AgentFeedbackComponent";
import { InvoiceInsightsWidget } from "Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/widgets/InvoiceInsightsWidget/InvoiceInsightsWidget";
import { DatePresenter } from "Core/Medius.Core.Web/Scripts/shared/components/datePresenter";
import { getUserTimeProvider } from "Core/Medius.Core.Web/Scripts/lib/date/date";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import { StreamErrorMessage } from "./StreamErrorMessage";

export interface IChatMessageProps {
    message: IMessage,
    invoiceType: string, 
    workflowStepName: string,
    retryCallback: () => void,
    showRetryButton: boolean
}

const getClasses = (message:IMessage) => {
    let classes = "mediusAgent__message";
    
    if(message.content === getLabelTranslation("#Core/agentNSFWWarningMessage")) {
        classes += " mediusAgent__message__nsfwWarning";
    } else if(message.role === Role.user) {
        classes += " mediusAgent__message__user";
    } else {
        classes += " mediusAgent__message__assistant";
    }

    if(message.invoiceContextDto || message.nonOperationalInvoiceContextDto || message.supplierWidgetDto) {
        classes += " mediusAgent__message__widgetContainer";
    } else {
        classes += " mediusAgent__message__textContainer";
    }

    return classes;
};

const getMarkdownClasses = (message:IMessage) => {
    let classes = "mediusAgent__message__textContainer__markdown";
    if(message.role == Role.user) {
        classes += " mediusAgent__message__textContainer__markdown__user";
    } else if (message.role == Role.assistant) {
        classes += " mediusAgent__message__textContainer__markdown__assistant";
    }

    return classes;
};

const shouldDisplayNonOperationalInvoiceInsights = (message: IMessage) => {
    return (
        message.nonOperationalInvoiceContextDto && 
        (
            message.nonOperationalInvoiceContextDto.latestComments.length > 0 || 
            message.nonOperationalInvoiceContextDto.previousInvoices.length > 0
        )
    );
};

const shouldDisplayMessage = (message: IMessage) => {
    return message.supplierWidgetDto ||
        shouldDisplayNonOperationalInvoiceInsights(message) ||
        message.content ||
        (
            message.invoiceContextDto && message.invoiceContextDto.isInvoiceAssignedToUser 
        );
};

const LinkRenderer = (props: any) => {
    return <a href={props.href} target="_blank" rel="noreferrer">
        {props.children}
    </a>;
};

export function ChatMessage({message, invoiceType, workflowStepName, retryCallback, showRetryButton}: IChatMessageProps) {
    const timeProvider = getUserTimeProvider();

    if(message.isError){
        return <StreamErrorMessage retryCallback={retryCallback} showRetryButton={showRetryButton} />;
    }

    return shouldDisplayMessage(message) ?
        <FlexLayout style={{minWidth: "0px"}} direction="row" spacerMode="on" spacerSize="medium">
            <div className={getClasses(message)}>
                {message.supplierWidgetDto && 
                <SupplierWidget supplierWidgetDto={message.supplierWidgetDto}/>}
            
                {message.content && 
                <ReactMarkdown data-testid="apa-copilot-message-text-content" components={{a: LinkRenderer}} className={getMarkdownClasses(message)} remarkPlugins={[gfm]}>
                    {message.content}
                </ReactMarkdown>
                }

                {message.sources.length > 0 && 
                <SourcesWidget sources={message.sources}/>}

                {
                message.invoiceContextDto && 
                !message.nonOperationalInvoiceContextDto && 
                message.invoiceContextDto.isInvoiceAssignedToUser &&
                    <InvoiceInsightsWidget invoiceContextDto={message.invoiceContextDto}/>
                }

                {
                message.nonOperationalInvoiceContextDto && 
                !message.invoiceContextDto &&
                    <InvoiceInsightsWidget nonOperationalContext={message.nonOperationalInvoiceContextDto} />
                }

                {message.role === Role.assistant && 
                    <FlexLayout direction="row" spacerMode="on" spacerSize="vsmall" className="mediusAgent__message__assistant__footer" data-testid="apa-copilot-assistant-message-footer">
                        <AgentFeedbackComponent 
                            documentId={message.documentId} 
                            conversationId={message.conversationId}
                            invoiceType={invoiceType}
                            workflowStepName={workflowStepName}/>
                        <div className="copilot-content-warning" style={{marginLeft: "auto"}}>
                            <Text alignment="right" variant="small"><DatePresenter date={timeProvider.toUserTimeZoneFromBrowserDate(new Date(message.date))} format="g"/></Text>
                            <Text variant="small" spacerMode="off">{getLabelTranslation("#Core/AIGeneratedContentMayBeIncorrect")}</Text>
                        </div>
                    </FlexLayout>
                }
            </div>
        </FlexLayout> :
    <></>;
}