///<amd-module name="Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/services/agentEventDispatchService" />
import { copilotEvents, translateThisInvoiceQuestionId, summarizeThisInvoiceQuestionId } from 'Core/Medius.Core.Web/Scripts/Medius/apps/task/Agent/AgentConsts';

export const dispatchTranslateCopilotEvent = () => {
    window.postMessage({
        type: copilotEvents.TranslateThisInvoice,
        payload: { questionId: translateThisInvoiceQuestionId }
    }, "*");
};

export const dispatchSummarizeCopilotEvent = () => {
    window.postMessage({
        type: copilotEvents.SummarizeThisInvoice,
        payload: { questionId: summarizeThisInvoiceQuestionId }
    }, "*");
};

export const dispatchFreeTextQuestionCopilotEvent = (prompt: string) => {
    window.postMessage({
        type: copilotEvents.FreeTextQuestion,
        payload: { prompt: prompt }
    }, "*");
};